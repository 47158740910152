<template>
  <v-row justify="start">
    <v-col cols="12">
      <base-subheading title="Realice su reserva" mobileSize="text-h5" />
    </v-col>

    <v-col cols="12" md="6">
      <date-picker @update:reservationDate="payload.date = $event" />
    </v-col>

    <v-col cols="12" md="6">
      <base-text-field
        v-model.number="payload.people"
        prepend-inner-icon="mdi-account-group"
        type="number"
        placeholder="Cantidad de Comensales"
        hide-details="auto"
      />
    </v-col>

    <v-col cols="12" class="px-6">
      <v-row
        v-if="loadingContent && payload.date && payload.people"
        justify="center"
      >
        <v-progress-circular
          :width="15"
          :size="100"
          color="rgba(184, 184, 184,0.4)"
          indeterminate
        />
      </v-row>
      <v-row v-else dense>
        <hour-proposal-single
          v-for="(hourProposal, index) in availableShifts"
          :key="index"
          :id="hourProposal.id"
          :available="hourProposal.available"
          :start-time="hourProposal.start_time"
          :selected-id="selectedId"
          @update:selectedId="selectedId = $event"
        />
      </v-row>
    </v-col>

    <v-col cols="12" class="pt-8">
      <v-row justify="center">
        <base-btn
          @click="
            $emit('update:shiftDetails', {
              date: payload.date,
              qtyPerson: payload.people,
              shift: selectedId,
            })
          "
          :disabled="!selectedId"
        >
          Continuar
        </base-btn>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import DatePicker from "@/components/reservation/available-shifts/DatePicker";
import HourProposalSingle from "@/components/reservation/available-shifts/HourProposalSingle";
import { reservationsService } from "@/services";

const { getAvailableShifts } = reservationsService;

export default {
  components: {
    DatePicker,
    HourProposalSingle,
  },

  data: () => {
    return {
      payload: {
        date: null,
        people: null,
      },
      selectedId: null,
      availableShifts: [],
      loadingContent: false,
    };
  },

  watch: {
    payload: {
      handler(newPayload) {
        this.validatePayload(newPayload);
      },
      deep: true,
    },
  },

  methods: {
    getAvailableShifts,
    validatePayload(payload) {
      if (payload.date && payload.people) {
        this.getContent(payload);
      } else {
        this.clearPayload();
      }
    },
    clearPayload() {
      this.selectedId = null;
      this.availableShifts = [];
    },
    getContent(payload) {
      this.loadingContent = true;
      this.getAvailableShifts(payload)
        .then((response) => {
          this.selectedId = null;
          this.availableShifts = response?.data?.data;
        })
        .finally(() => {
          this.loadingContent = false;
        });
    },
  },
};
</script>
